<template>
  <div id="home">
    <!-- HERO BANNER SECTION -->
    <v-sheet class="hero-banner px-5">
      <v-sheet :max-width="containerWidth" color="transparent" class="mx-auto position-relative">
        <v-row>
          <!-- LEFT COLUMN -->
          <v-col cols="12" md="6" class="text-center text-md-left" data-aos="fade-right" data-aos-duration="1000">
            <h1 class="white--text text-center text-md-left font-weight-bold heading-font mb-3" v-html="headline._value"></h1>
            <div class="subheading white--text text-center text-md-left heading-font opacity-8" v-html="subHeadline._value"></div>

            <!-- FORM -->
            <v-sheet max-width="576" class="d-inline-block" color="transparent">
              <v-form ref="projectForm" @submit.prevent="validateForm()">
                <v-text-field
                  class="text-field white--text rounded-lg"
                  placeholder="Project Name"
                  :rules="[rules.required]"
                  v-model="data.name"
                  hide-details
                  rounded
                  filled
                  dark
                >
                  <template v-if="$vuetify.breakpoint.mdAndUp" #append>
                    <v-btn 
                      @click="validateForm()"
                      :loading="status.creating"
                      class="action-btn rounded-lg" 
                      color="accent gradient"
                    >Start Creating For Free</v-btn>
                  </template>
                </v-text-field>

                <v-btn 
                  v-if="$vuetify.breakpoint.smAndDown"
                  @click="validateForm()"
                  :loading="status.creating"
                  class="action-btn rounded-lg mt-5" 
                  color="accent gradient"
                  block
                >Start Creating For Free</v-btn>
              </v-form>

              <div class="mt-5 white--text heading-font opacity-5 text-center text-md-left" v-html="inputBottomText._value"></div>
            </v-sheet>
          </v-col>
          <!-- RIGHT COLUMN -->
          <v-col cols="12" md="6" class="d-sm-none d-md-flex align-center justify-end flex-grow-1 text-right right-col">
            <!-- IMAGE 1 -->
            <div 
              data-aos="fade-down" 
              data-aos-duration="800" 
              class="image-1"
            >
              <div>
                <div data-aos="zoom-in" data-aos-delay="1000" class="dot">
                  <div><span>No Design Skills Needed</span></div>
                </div>
              </div>
            </div>

            <!-- IMAGE 2 -->
            <div 
              data-aos="fade-left" 
              data-aos-duration="800" 
              class="image-2"
              v-rellax="{ speed: 1 }"
            >
              <div>
                <div data-aos="zoom-in" data-aos-delay="1500" class="dot">
                  <div><span>100% Free Access</span></div>
                </div>
              </div>
            </div>

            <!-- DASHED BOX -->
            <div class="dashed-1"></div>
            <div class="dashed-2"></div>

            <!-- VIDEO 1 -->
            <div 
              data-aos="zoom-in" 
              data-aos-duration="800" 
              class="video-1"
              v-rellax="{ speed: 0.3 }"
            >
              <div>
                <div data-aos="zoom-in" data-aos-delay="2000" class="dot">
                  <div><span>Design Unlimited Pages</span></div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        
        <!-- lOGO SECTION -->
        <div class="logo-section align-center justify-center d-flex flex-wrap">
          <div><v-img src="/home/logo-1.png" :width="$vuetify.breakpoint.smAndDown ? '112' : '140'" min-height="50" contain /></div>
          <div><v-img src="/home/logo-2.png" :width="$vuetify.breakpoint.smAndDown ? '112' : '145'" min-height="50" contain /></div>
          <div><v-img src="/home/logo-3.png" :width="$vuetify.breakpoint.smAndDown ? '90' : '125'" min-height="50" contain /></div>
          <div><v-img src="/home/logo-4.png" :width="$vuetify.breakpoint.smAndDown ? '112' : '145'" min-height="50" contain /></div>
          <div><v-img src="/home/logo-5.png" :width="$vuetify.breakpoint.smAndDown ? '112' : '140'" min-height="50" contain /></div>
          <div><v-img src="/home/logo-6.png" :width="$vuetify.breakpoint.smAndDown ? '112' : '145'" min-height="50" contain /></div>
        </div>
      </v-sheet>
      <!-- END lOGO SECTION -->
    </v-sheet>
    <!-- END HERO BANNER SECTION -->

    <!-- MIDDLE SECTION -->
    <div class="middle-section px-5">

      <template v-if="$vuetify.breakpoint.mdAndUp">
        <!-- IMG 4 -->
        <v-img 
          src="/home/bg-lines-2@1x.svg" 
          width="284px"
          height="728px"
          contain
          :style="{
            position: 'absolute',
            right: 0,
            top: '1240px',
          }"
        />
        
        <!-- IMG 5 -->
        <v-img 
          src="/home/bg-lines-3@1x.svg"
          width="119px"
          height="354px"
          contain
          :style="{
            position: 'absolute',
            left: 0,
            top: '2300px',
          }"
        />

        <!-- IMG 1 -->
        <v-img 
          src="/home/welcome-video-bg-shape-2@1x.svg" 
          height="524px"
          width="470px"
          contain
          :style="{
            position: 'absolute',
            right: 0,
            top: '400px',
          }"
        />
        
        <!-- IMG 2 -->
        <v-img 
          src="/home/feature-1-bg-shape-light@1x.svg" 
          height="813px"
          width="702px"
          contain
          :style="{
            position: 'absolute',
            left: 0,
            top: '784px',
          }"
        />
        
        <!-- IMG 3 -->
        <v-img 
          src="/home/feature-2-bg-shape-light@1x.svg" 
          height="814px"
          width="711px"
          contain
          :style="{
            position: 'absolute',
            right: 0,
            top: '1700px',
          }"
        />
      </template>
      
      <!-- MIDDLE 1 -->
      <v-sheet :max-width="containerWidth" color="transparent" class="mx-auto" :style="{ marginBottom: $vuetify.breakpoint.mdAndUp ? '418px' : '20px' }">
        <v-row align="center" class="middle-1">
          <!-- LEFT COLUMN -->
          <v-col cols="12" md="6" class="text-center text-md-left" data-aos="fade-right" data-aos-duration="1000">
            <div class="content-chip d-inline-block">Welcome</div>
            <h3 class="mt-5 mb-3" v-html="m1Headline._value"></h3>
            <p v-html="m1Text._value"></p>
          </v-col>
          <!-- RIGHT COLUMN` -->
          <v-col cols="12" md="6" class="right-col position-relative">
            <div class="bg-1" v-rellax="{ speed: $vuetify.breakpoint.mdAndUp ? 3 : 0.5 }"></div>
            <v-img 
              src="/home/video-arrow-line-small-left.svg" 
              class="position-absolute d-block d-md-none"
              :style="{ left: '-8px', top: '-28px' }"
              height="27"
              width="46"
              contain
            />
            <v-img 
              src="/home/video-arrow-line-small-right.svg" 
              class="position-absolute d-block d-md-none"
              :style="{ right: '-8px', top: '-28px' }"
              height="27"
              width="46"
              contain
            />
            
            <div 
              data-aos="fade-left" 
              class="video-1"
            >
              <div class="content">
                <div v-html="homeVideo._value"></div>
                <!-- <v-btn data-aos="zoom-in" data-aos-delay="500" color="accent gradient" class="text-none" rounded>Watch It In Action!</v-btn> -->
                <v-img data-aos="fade-right" data-aos-delay="300" class="video-arrow" src="/home/video-arrow-line.svg" width="356.24" height="83.25" contain />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- MIDDLE 2 -->
      <v-sheet :max-width="containerWidth" color="transparent" class="mx-auto" :style="{ marginBottom: $vuetify.breakpoint.mdAndUp ? '406px' : '-107px' }">
        <v-row class="middle-2">
          <!-- LEFT -->
          <v-col cols="12" md="6" class="left-col text-center text-md-left">
            <div class="bg-1 fill-height"></div>
            <div class="dash-1"></div>
            <div class="dash-2"></div>
            <div data-aos="fade-left" class="img-1" v-rellax="{ speed: 0.1 }"><div class="content"></div></div>
            <div data-aos="fade-down" class="img-2" v-rellax="{ speed: 1 }"><div class="content"></div></div>
            <div data-aos="fade-left" class="img-3" v-rellax="{ speed: 0.3 }"><div class="content"></div></div>
            <div data-aos="fade-right" class="img-4" v-rellax="{ speed: 0.5 }"><div class="content"></div></div>
            <div 
              class="btn-1" 
              data-aos="zoom-in" 
              data-aos-delay="1000"
            >
              <v-avatar
                size="32"
              >
                <v-img 
                  src="/home/add-icon.svg" 
                  :width="$vuetify.breakpoint.mdAndUp ? '32' : '24'" 
                  :height="$vuetify.breakpoint.mdAndUp ? '32' : '24'" 
                  contain 
                />
              </v-avatar>
              <span>Choose A Header</span>
            </div>
            <div class="btn-2" data-aos="zoom-in" data-aos-delay="1100">
              <v-avatar
                size="32"
              >
                <v-img 
                  src="/home/add-icon.svg" 
                  :width="$vuetify.breakpoint.mdAndUp ? '32' : '24'" 
                  :height="$vuetify.breakpoint.mdAndUp ? '32' : '24'" 
                  contain 
                />
              </v-avatar>
              <span>Choose A Section</span>
            </div>
            <div class="btn-3" data-aos="zoom-in" data-aos-delay="1200">
              <v-avatar
                size="32"
              >
                <v-img 
                  src="/home/add-icon.svg" 
                  :width="$vuetify.breakpoint.mdAndUp ? '32' : '24'" 
                  :height="$vuetify.breakpoint.mdAndUp ? '32' : '24'" 
                  contain 
                />
              </v-avatar>
              <span>Choose A Footer</span>
            </div>
          </v-col>
          <!-- RIGHT -->
          <v-col 
            cols="12" md="6"
            data-aos-duration="1000"
            data-aos="fade-left" 
            class="right-col text-center text-md-left"
          >
            <v-sheet color="transparent" max-width="471" class="d-inline-block">
              <div class="content-chip d-inline-block">Simple To Use</div>
              <h3 class="my-5" v-html="m2Headline._value"></h3>
              <p class="mb-7" v-html="m2Text._value"></p>
              <template v-if="Object.values(m2List).length">
                <div 
                  v-for="(list, i) in Object.values(JSON.parse(m2List._value))"
                  :key="i"
                  class="list"
                >
                  <v-btn color="#eff1f7" class="mr-3" icon outlined small>
                    <v-icon small color="#6dd500">mdi-check</v-icon>
                  </v-btn>
                  <span>{{ list }}</span>
                </div>
              </template>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- MIDDLE 3 -->
      <v-sheet :max-width="containerWidth" color="transparent" class="mx-auto" :style="{ marginBottom: $vuetify.breakpoint.mdAndUp ? '230px' : '30px' }">
        <v-row align="center" class="middle-3">
          <!-- LEFT COL -->
          <v-col 
            cols="12" md="6"
            data-aos-duration="1000"
            data-aos="fade-right"
            class="left-col text-center text-md-left"
          >
            <div class="content-chip d-inline-block">Beautiful Designs</div>
            <h3 class="my-5" v-html="m3Headline._value"></h3>
            <p class="mb-7 text-center text-md-left d-block" :class="{ 'mx-auto' : $vuetify.breakpoint.smAndDown }" :style="{ maxWidth: '471px' }" v-html="m3Text._value"></p>
            <template v-if="Object.keys(m3List).length">
              <div 
                v-for="(list, i) in Object.values(JSON.parse(m3List._value))"
                :key="i"
                class="list"
              >
                <v-btn color="#eff1f7" class="mr-3" icon outlined small>
                  <v-icon small color="#6dd500">mdi-check</v-icon>
                </v-btn>
                <span>{{ list }}</span>
              </div>
            </template>
          </v-col>
          
          <!-- RIGHT COL -->
          <v-col cols="12" md="6" class="right-col">
            <div class="bg-1">
              <div class="img-1 d-none d-md-block" data-aos="fade-down" data-aos-delay="500"><div></div></div>
              <div class="img-2 d-none d-md-block" data-aos="fade-left" data-aos-delay="650" v-rellax="{ speed: 0.3 }"><div></div></div>
              <div class="img-3 d-none d-md-block" data-aos="fade-up" data-aos-delay="800"><div></div></div>
              <div class="dash-1 d-none d-md-block"></div>
              <div class="dash-2 d-none d-md-block"></div>

              <div class="window" data-aos="fade-up" v-rellax="{ speed: 0.5 }">
                <div class="head">
                  <v-icon color="#fa5c5c" size="12">mdi-checkbox-blank-circle</v-icon>
                  <v-icon color="#ffce49" size="12">mdi-checkbox-blank-circle</v-icon>
                  <v-icon color="#8fdf5d" size="12">mdi-checkbox-blank-circle</v-icon>
                </div>
                <div class="body">
                  <div></div>
                </div>
              </div>
            </div>

            <div class="blur-1" data-aos="zoom-in" data-aos-delay="1000">Quick Build Publish</div>
            <div class="blur-2" data-aos="zoom-in" data-aos-delay="1200">Drag & Drop Interface</div>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- MIDDLE 4 -->
      <v-sheet :max-width="containerWidth" color="transparent" class="mx-auto text-center text-md-left">
        <div class="content-chip d-inline-block">Lightning Builder</div>
        <div class="d-flex align-center justify-center justify-md-space-between flex-wrap mt-5 gap-20">
          <h3 v-html="bottomHeadline._value"></h3>
          <v-btn href="#cta-form" color="grey lighten-2" outlined><span class="primary--text">Get Started Today</span></v-btn>
        </div>
        <v-divider class="mt-7 mb-10"></v-divider>
      </v-sheet>
     
      <!-- BOTTOM -->
      <div class="bottom">
        <v-sheet 
          class="mx-auto d-flex flex-wrap justify-center justify-md-space-between gap-20"
          :style="{ marginBottom: '86px', marginTop: '-15px' }"
          :max-width="containerWidth" 
          color="transparent" 
        >
          <!-- CARDS -->
          <template v-if="Object.keys(bottomCards).length">
            <div
              v-for="(card, i) in Object.values(JSON.parse(bottomCards._value))"
              :key="i"
              :data-aos-delay="200 * (i + 1)"
              data-aos="fade-up" 
              class="card" 
            >
              <v-sheet min-height="120" class="mb-3 d-flex justify-center justify-md-start">
                <div><v-img :src="card.icon" width="96" contain /></div>
              </v-sheet>

              <h5 v-html="card.title" class="text-center text-md-left"></h5>
              <v-divider class="my-5"></v-divider>
              <p v-html="card.content"></p>
            </div>
          </template>
        </v-sheet>

        <!-- CALL TO ACTION -->
        <div class="text-center">
          <h2 data-aos="zoom-in" data-aos-delay="300" class="text-center" v-html="bottomCtaTitle._value"></h2>
          <p data-aos="zoom-in" data-aos-delay="400" class="text-center cta-sub" v-html="bottomCtaText._value"></p>
          
          <!-- FORM -->
            <v-sheet data-aos="zoom-in" data-aos-delay="500" max-width="576" width="100%" class="d-inline-block" color="transparent">
              <v-form id="cta-form" ref="projectForm" @submit.prevent="validateForm()">
                <v-text-field
                  class="text-field white--text rounded-lg"
                  placeholder="Project Name"
                  :rules="[rules.required]"
                  v-model="data.name"
                  hide-details
                  rounded
                  filled
                  dark
                >
                  <template v-if="$vuetify.breakpoint.mdAndUp" #append>
                    <v-btn 
                      :style="{marginTop: '-10px !important', marginBottom: '8px !important', marginRight: '-15px'}"
                      @click="validateForm()"
                      :loading="status.creating"
                      class="action-btn rounded-lg" 
                      color="accent gradient"
                    >Start Creating For Free</v-btn>
                  </template>
                </v-text-field>

                <v-btn 
                  v-if="$vuetify.breakpoint.smAndDown"
                  @click="validateForm()"
                  :loading="status.creating"
                  class="action-btn rounded-lg mt-5" 
                  color="accent gradient"
                  block
                >Start Creating For Free</v-btn>
              </v-form>
            </v-sheet>
        </div>
      </div>
    </div>
    

    <!-- FOOTER -->
    <div class="footer">
      <v-sheet 
        :max-width="containerWidth" 
        color="transparent" 
        class="mx-auto d-flex justify-center justify-md-space-between align-center flex-wrap gap-20 px-5"
      >
        <div>
          <v-img 
            :src="require('../assets/Lightning_Builder_Logo.svg')"
            :width="$vuetify.breakpoint.mdAndUp ? '250' : '148'"
            contain
            left
          ></v-img>
        </div>

        <div class="text" v-html="copyright._value"></div>

        <v-btn 
          @click="
            $store.commit('user/setShowDialog', true)
            $store.commit('user/setActionType', 'signup')
          "
          color="grey lighten-2" 
          outlined
        >
          <span class="primary--text px-5">Sign Up Today</span>
        </v-btn>
      </v-sheet>
    </div>
  </div>
</template>

<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'

import Vue from 'vue'
import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

import rules from '@/rules'
import { mapState } from 'vuex'
import firebase from 'firebase'

export default {
  metaInfo: {
    title: 'Home',
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      m1Text: {},
      m2Text: {},
      m2List: {},
      m3Text: {},
      m3List: {},
      headline: {},
      copyright: {},
      homeVideo: {},
      m1Headline: {},
      m2Headline: {},
      m3Headline: {},
      subHeadline: {},
      bottomCards: {},
      bottomCtaText: {},
      bottomHeadline: {},
      bottomCtaTitle: {},
      inputBottomText: {},
      containerWidth: '1240',
    }
  },

  
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      data: state => state.projects.data,
      status: state => state.projects.status,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH 
   *----------------------------------------------------------------------------*/
  watch: {
    'user': function (val) {
      if (val) {
        this.$router.push({ name: 'Projects' })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    validateForm() {
      if (this.$refs.projectForm.validate()) {
        this.$store.dispatch('projects/create', )
      }
    },

    // GET REMOTE CONFIGS
    getConfigs() {
      let remoteConfig = firebase.remoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 15000
      
      remoteConfig.fetchAndActivate()
      .then(async () => {
        // HERO BANNER
        Promise.all([
          this.headline = remoteConfig.getValue('hero_headline'),
          this.subHeadline = remoteConfig.getValue('hero_sub_headline'),
          this.inputBottomText = remoteConfig.getValue('hero_input_bottom_text'),
          this.m1Headline = remoteConfig.getValue('m1_headline'),
          this.m1Text = remoteConfig.getValue('m1_text'),
          this.m2Headline = remoteConfig.getValue('m2_headline'),
          this.m2Text = remoteConfig.getValue('m2_text'),
          this.m2List = remoteConfig.getValue('m2_list'),
          this.m3Headline = remoteConfig.getValue('m3_headline'),
          this.m3Text = remoteConfig.getValue('m3_text'),
          this.m3List = remoteConfig.getValue('m3_list'),
          this.bottomHeadline = remoteConfig.getValue('bottom_headline'),
          this.bottomCards = remoteConfig.getValue('bottom_cards'),
          this.bottomCtaTitle = remoteConfig.getValue('bottom_cta_title'),
          this.bottomCtaText = remoteConfig.getValue('bottom_cta_text'),
          this.copyright = remoteConfig.getValue('copyright'),
          this.homeVideo = remoteConfig.getValue('home_video'),
        ])
        .then(() => {
          AOS.init()
        })
        
      })
      .catch(error => {
        console.log(error.message)
      })
    },
  },

  /*------------------------------------------------------------------------------
   * BEFORE CREATE
   *----------------------------------------------------------------------------*/
  beforeMount() {
    this.getConfigs()
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.$store.commit('setViewState', 'view')

    if (this.$cookies.get('project') && !firebase.auth().currentUser) {
      this.$router.push({ name: 'Project', params: { id: this.$cookies.get('project') } })
    }
  }
}
</script>


<style lang="scss">
  @import '../sass/home.scss';
</style>